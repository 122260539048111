import { PhoneFilterValues } from '@capturi/api-filters'
import { useCurrentUser } from '@capturi/core'
import { Tag, Wrap, WrapItem } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import React, { ReactElement } from 'react'

import { useFilterDefinitions } from '../../definitions'
import { toFilterValues } from '../../mappers'

type Props = {
  values: PhoneFilterValues | undefined
}

const FilterTags: React.FC<Props> = ({ values }) => {
  const state = toFilterValues(values ?? {})
  const currentUser = useCurrentUser()
  const definitions = useFilterDefinitions(currentUser)

  const components = Object.entries(state).reduce<React.ReactElement[]>(
    (memo, [key, value]) => {
      const keyWithType = key as keyof PhoneFilterValues
      const filterDefinition = definitions.get(keyWithType)
      if (!filterDefinition) {
        return memo
      }

      // Handle multiple variants of the given filter
      if (filterDefinition.allowMultiple && Array.isArray(value)) {
        return memo.concat(
          (value as unknown[]).reduce<ReactElement[]>((memo, x) => {
            const hasValue = filterDefinition.hasValue?.(x) ?? !isEmpty(x)
            if (hasValue) {
              memo.push(filterDefinition.renderText(x))
            }
            return memo
          }, []),
        )
      }

      // Handle single variant of the given filter
      const hasValue = filterDefinition.hasValue?.(value) ?? !isEmpty(value)
      if (hasValue) {
        memo.push(filterDefinition.renderText(value))
      }
      return memo
    },
    [],
  )

  return (
    <Wrap spacing={1}>
      {components.map((C, i) => (
        <WrapItem key={i}>
          <Tag
            size="sm"
            rounded="full"
            variant="subtle"
            minH="auto"
            py={1}
            px={2}
            lineHeight={1}
            fontWeight="normal"
            flexShrink={1}
          >
            {C}
          </Tag>
        </WrapItem>
      ))}
    </Wrap>
  )
}

export default FilterTags
