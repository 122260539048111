import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { usePageTitle } from '@capturi/react-utils'
import { PageHeading, Spinner } from '@capturi/ui-components'
import { Tab, TabList, Tabs } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React, { useCallback } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom'

import NotFoundPage from '../../pages/NotFoundPage'
import { TabsConfig, useTabsRouteState } from '../../utils/useRoutableTabs'
import SavedPhoneFilters from './SavedPhoneFilters'
import SavedTextFilters from './SavedTextFilters'
import { SavedFiltersTab, routes } from './routes'

const SavedFilters: React.FC = () => {
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const { useEmailChannelAsDefault, enableText } = useFeatureFlags()

  const routeMatch = useMatch('/settings/segments/:tabName')
  let initialTab: SavedFiltersTab | undefined
  if (enableText && currentUser.permissions.text) {
    if (routeMatch) {
      initialTab = routeMatch.params.tabName as SavedFiltersTab
    } else {
      initialTab = useEmailChannelAsDefault ? 'email' : 'phone'
    }
  } else {
    initialTab = 'phone'
  }

  const { tabIndex, getTab, tabs } = useTabsRouteState<SavedFiltersTab>(() => {
    const routes: TabsConfig<SavedFiltersTab> = [
      {
        value: 'phone',
        tab: t`Phone`,
      },
    ]

    if (enableText && currentUser.permissions.text) {
      return [
        ...routes,
        {
          value: 'email',
          tab: t`Text`,
        },
      ]
    }

    return routes
  }, initialTab)

  const handleTabsChange = useCallback(
    (index: number): void => {
      const tabName = getTab(index).value
      navigate(routes.tab(tabName))
    },
    [getTab, navigate],
  )

  return (
    <>
      <PageHeading mb={8}>{t`Segments`}</PageHeading>
      <Tabs mb="4" index={tabIndex} onChange={handleTabsChange} isLazy>
        <TabList>
          {tabs.map((o) => (
            <Tab key={o.value}>{o.tab}</Tab>
          ))}
        </TabList>
      </Tabs>
      <React.Suspense fallback={<Spinner />}>
        <Outlet />
      </React.Suspense>
    </>
  )
}

const SavedFiltersPage: React.FC = () => {
  usePageTitle(t`Segments`)
  const currentUser = useCurrentUser()
  const { useEmailChannelAsDefault, enableText } = useFeatureFlags()

  if (!currentUser.permissions.editSegment) return <NotFoundPage />

  return (
    <Routes>
      <Route path="/" element={<SavedFilters />}>
        <Route
          index
          element={
            <Navigate
              to={useEmailChannelAsDefault ? 'email' : 'phone'}
              replace
            />
          }
        />
        {enableText && currentUser.permissions.text ? (
          <>
            (!useEmailChannelAsDefault && (
            <Route path="phone" element={<SavedPhoneFilters />} />)
            <Route path="email" element={<SavedTextFilters />} />
          </>
        ) : (
          <Route path="phone" element={<SavedPhoneFilters />} />
        )}
      </Route>
    </Routes>
  )
}

export default SavedFiltersPage
