import {
  Box,
  BoxProps,
  ControlBox,
  ControlBoxProps,
  VisuallyHidden,
} from '@chakra-ui/react'
import React from 'react'
import { MdCheck, MdClose } from 'react-icons/md'

export type IconSwitchProps = {
  'aria-label'?: string
  'aria-labelledby'?: string
  'aria-describedby'?: string
  colorScheme?: string
  id?: string
  icon?: React.ReactElement
  isChecked: boolean
  isDisabled?: boolean
  enabledIcon?: React.ReactElement
  disabledIcon?: React.ReactElement
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  size?: SizeProp
} & Omit<BoxProps, 'size' | 'onChange'>

type SizeProp = 'xs' | 'sm' | 'md' | 'lg'

const getSizeProps = (size: SizeProp): ControlBoxProps => {
  const sizes = {
    lg: {
      height: '36px',
      minWidth: '36px',
      fontSize: '1.4rem',
    },
    md: {
      height: '32px',
      minWidth: '32px',
      fontSize: '1.2rem',
    },
    sm: {
      height: '28px',
      minWidth: '28px',
      fontSize: '1rem',
    },
    xs: {
      height: '24px',
      minWidth: '24px',
      fontSize: '0.875rem',
    },
  }
  return sizes[size] ?? sizes.sm
}

const IconSwitch = React.forwardRef<HTMLDivElement, IconSwitchProps>(
  function IconSwitch(props, ref) {
    const {
      id,
      isChecked,
      isDisabled,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      'aria-describedby': ariaDescribedby,
      onChange,
      size = 'sm',
      colorScheme = 'primary',
      icon,
      enabledIcon = <MdCheck />,
      disabledIcon = <MdClose />,
      ...restProps
    } = props

    const sizeProps = getSizeProps(size)

    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
      onChange?.(event)
    }

    return (
      <Box as="label" ref={ref} {...restProps}>
        <VisuallyHidden
          as="input"
          id={id}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          aria-describedby={ariaDescribedby}
          onChange={handleInputChange}
          {...{
            type: 'checkbox',
            checked: isChecked,
            disabled: isDisabled,
          }}
        />
        <ControlBox
          borderWidth="1px"
          rounded="full"
          _checked={{
            bg: `${colorScheme}.500`,
            color: 'white',
            borderColor: `${colorScheme}.500`,
          }}
          _focus={{
            borderColor: `${colorScheme}.600`,
            boxShadow: 'outline',
          }}
          _disabled={{
            opacity: 0.5,
          }}
          _child={{ opacity: 1 }}
          cursor={isDisabled ? 'auto' : 'cursor'}
          {...sizeProps}
        >
          {icon ? icon : isChecked ? enabledIcon : disabledIcon}
        </ControlBox>
      </Box>
    )
  },
)

export default IconSwitch
