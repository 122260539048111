import { IconSwitch, IconSwitchProps } from '@capturi/ui-components'
import React from 'react'
import { MdNotificationsActive, MdNotificationsOff } from 'react-icons/md'

const FilterSubscribeSwitch = React.forwardRef<HTMLDivElement, IconSwitchProps>(
  function FilterSubscribeSwitch(props, ref) {
    return (
      <IconSwitch
        ref={ref}
        enabledIcon={<MdNotificationsActive />}
        disabledIcon={<MdNotificationsOff />}
        {...props}
      />
    )
  },
)

export default FilterSubscribeSwitch
